import { makeAutoObservable, runInAction } from 'mobx';
import { arrayToTree } from 'performant-array-to-tree';
import { Translator, parameterStore, parameterValueStore, sectionParameterStore, toastStore, xmlDocumentInstanceStore } from 'stores';
import ParameterGroupService from 'services/ParameterGroupService';
import { DEFAULT_WIDTH_TREE } from 'shared/constants/constants';
import { Routes } from 'shared/enums/Routes';
import { IGetAllParameterGroupDto, IParameterGroupByIdDto, IParameterValueTreeDto, ISearch, ISelectOption, ITabItem } from 'shared/interfaces';
import { IShortParameterGroupList } from 'shared/interfaces/app/IParameterGroup';
import { ParameterGroupModel } from 'shared/models';
import { RootParameterGroupModel } from 'shared/models/ParameterGroupModel';
import TreeModel from 'shared/models/TreeModel';
import Utils from 'shared/utils/Utils';

const FIRST_ORDER_NUM = 1;

class ParameterGroupStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public waitingParameterGroup = false;

  public updateLoading = false;

  public parameterGroupTree: ParameterGroupModel[] = [];

  public listTransformParamGroupDto: IShortParameterGroupList[] = [];

  public selectedParamGroup = new ParameterGroupModel();

  public expandedKeys: React.Key[] = [];

  public checkedKeys: string[] = [];

  public treeParameterGroup = new TreeModel();

  public parameterGroupById: IParameterGroupByIdDto | null = null;

  public rootParameterGroup: RootParameterGroupModel[] = [];

  public isEditRootParameterGroup = false;

  public currentParameterGroup: number | null = null;

  public copyParamGroupId: number | null = null;

  public isDialogOpen = false;

  public searchArrays: ISearch[] = [];

  public searchValue = '';

  public clickSearch = false;

  public treeParameterGroupWidth = DEFAULT_WIDTH_TREE;

  public get rootGroupForOptions(): ISelectOption[] {
    return this.rootParameterGroup.map((item) => {
      return {
        id: item.id!,
        name: item.name,
      };
    });
  }

  public get isSelectedTreeGroupFirst(): boolean {
    const findEl = this.listTransformParamGroupDto.find((dto) => dto.id === parameterStore.filterParameters.parameterGroupId);

    return findEl?.orderNum === FIRST_ORDER_NUM;
  }

  public get isSelectedTreeGroupLast(): boolean {
    const findEl = this.listTransformParamGroupDto.find((dto) => dto.id === parameterStore.filterParameters.parameterGroupId);
    const parameterGroups = this.listTransformParamGroupDto.filter((dto) => dto.parentId === findEl?.parentId);

    return parameterGroups.length === findEl?.orderNum;
  }

  public isSelectedRootGroupFirst(rootGroup: number): boolean {
    return this.rootParameterGroup[0]?.id === rootGroup;
  }

  public isSelectedRootGroupLast(rootGroup: number): boolean {
    return this.rootParameterGroup[this.rootParameterGroup.length - 1]?.id === rootGroup;
  }

  public setParameterGroupWidth(value: number) {
    this.treeParameterGroupWidth = value;
  }

  public setCopyParamGroupId(id: number | null) {
    this.copyParamGroupId = id;
  }

  public setIsDialogOpen(value: boolean) {
    this.isDialogOpen = value;
  }

  public setCurrentParameterGroup(id: number | null) {
    this.currentParameterGroup = id;
  }

  public setEditRootParameterGroup(value: boolean) {
    this.isEditRootParameterGroup = value;
  }

  public changeOrder(order: 1 | -1) {
    this.rootParameterGroup = Utils.sortByField(
      this.rootParameterGroup.map((p) => {
        if (order === 1 && p.orderNum === this.selectedParamGroup.orderNum! + 1) {
          p.orderNum -= 1;
        }

        if (order == -1 && p.orderNum === this.selectedParamGroup.orderNum! - 1) {
          p.orderNum += 1;
        }

        if (p.id === this.currentParameterGroup) {
          p.orderNum = p.orderNum! + order;
        }

        return p;
      }),
      'orderNum'
    );

    this.selectedParamGroup.setOrderNum(this.selectedParamGroup.orderNum! + order);
  }

  public setExpandedKeys(expandedKeys: React.Key[]) {
    this.expandedKeys = expandedKeys;
  }

  public setSelectParameterGroup(keys: React.Key[], info?: any) {
    if (keys?.length !== 0) {
      parameterStore.filterParameters.setParameterGroupId(info.selectedNodes[0].id);
    }
  }

  public setCheckedParameterGroup(checkedKeys: string[]) {
    this.checkedKeys = checkedKeys;
  }

  public setSearchArrays(searchArrays: ISearch[]) {
    this.searchArrays = searchArrays;
  }

  public setClickSearch(clickSearch: boolean) {
    this.clickSearch = clickSearch;
  }

  public resetCheckedNodes() {
    this.checkedKeys = [];
  }

  public setSearchValue(searchValue: string) {
    this.searchValue = searchValue;
  }

  public setLoadingParameterGroup(value: boolean) {
    this.waitingParameterGroup = value;
  }

  public async defaultExpandSelectParamGroup() {
    //раскрытие узла и выставление фильтра (первый элемент в дереве после элемента глобальной группы)
    const expandedKey = this.parameterGroupTree.length > 0 ? this.parameterGroupTree[0].children[0]['key'] : this.parameterGroupTree[0]['key'];

    const currentParameterGroup = this.parameterGroupTree.length > 0 ? this.parameterGroupTree[0].children[0] : this.parameterGroupTree[0];

    this.expandedKeys.push(expandedKey);
    this.selectedParamGroup = new ParameterGroupModel(currentParameterGroup);
    sectionParameterStore.paramGroupFilter = new ParameterGroupModel(currentParameterGroup);
    await sectionParameterStore.getParametersSection();
  }

  public defaultExpandSelectGroupNavigation() {
    //раскрытие узла и выставление выбранного элемента (первый элемент в дереве после элемента глобальной группы)
    const expandedKey = this.parameterGroupTree.length > 0 ? this.parameterGroupTree[0].children[0]['key'] : this.parameterGroupTree[0]['key'];

    const currentParameterGroup = this.parameterGroupTree.length > 0 ? this.parameterGroupTree[0].children[0] : this.parameterGroupTree[0];

    this.expandedKeys.push(expandedKey);
    this.selectedParamGroup = new ParameterGroupModel(currentParameterGroup);
  }

  public async defaultExpandSelectXMLParamGroup() {
    //раскрытие узла и выставление фильтра (первый элемент в дереве после элемента глобальной группы)
    const expandedKey = this.parameterGroupTree.length > 0 ? this.parameterGroupTree[0].children[0]['key'] : this.parameterGroupTree[0]['key'];

    const currentParameterGroup = this.parameterGroupTree.length > 0 ? this.parameterGroupTree[0].children[0] : this.parameterGroupTree[0];

    this.expandedKeys.push(expandedKey);
    this.selectedParamGroup = new ParameterGroupModel(currentParameterGroup);
    xmlDocumentInstanceStore.paramGroupFilter = new ParameterGroupModel(currentParameterGroup);
    await xmlDocumentInstanceStore.getParameterGroupParameters();
  }

  public createParamGroupTreeData(dto: IGetAllParameterGroupDto[] | IParameterValueTreeDto[]): ParameterGroupModel[] {
    const transformDto: IShortParameterGroupList[] = [];
    const expandedNodes: React.Key[] = [];

    const expandedIds = Utils.filterNodesIds(dto, 0);
    dto.forEach((value: IGetAllParameterGroupDto | IParameterValueTreeDto) => {
      const newItem: IShortParameterGroupList = {
        key: value.id,
        title: value.name,

        isPresented: (value as IParameterValueTreeDto) ? (value as IParameterValueTreeDto).isPresented : false,

        id: value.id,
        orderNum: (value as IGetAllParameterGroupDto) ? (value as IGetAllParameterGroupDto).orderNum : undefined,
        name: value.name,
        fullName: (value as IGetAllParameterGroupDto) ? (value as IGetAllParameterGroupDto).fullName : undefined,
        parentId: value.parentId,
      };

      if (expandedIds.has(value.id)) {
        expandedNodes.push(newItem.key);
      }
      transformDto.push(newItem);
    });

    this.listTransformParamGroupDto = transformDto;

    //при смене корневой группы (все узлы дерева делаем раскрытыми)
    if (!this.expandedKeys.length || this.expandedKeys[0] !== expandedNodes[0]) {
      this.setExpandedKeys(expandedNodes);
    }

    return arrayToTree(transformDto, { dataField: null }).map((item) => new ParameterGroupModel(item));
  }

  public async initFilterStateParamGroup() {
    this.setLoadingParameterGroup(true);
    await this.getParameterGroups();
    this.setLoadingParameterGroup(false);

    //раскрытие узла и выставление фильтра (первый элемент в дереве после элемента глобальной группы)

    const expandedKey =
      this.parameterGroupTree.length > 0 && this.parameterGroupTree[0].children.length > 0 && !this.isEditRootParameterGroup
        ? this.parameterGroupTree[0].children[0]['key']
        : this.parameterGroupTree[0]['key'];
    const currentParameterGroup =
      this.parameterGroupTree.length > 0 && this.parameterGroupTree[0].children.length > 0 && !this.isEditRootParameterGroup
        ? this.parameterGroupTree[0].children[0]
        : this.parameterGroupTree[0];

    this.expandedKeys.push(expandedKey);
    parameterStore.filterParameters.setParameterGroupId(currentParameterGroup.id);
  }

  public setParameterGroupsMergeNavigation(data: IParameterValueTreeDto[]) {
    this.parameterGroupTree = this.createParamGroupTreeData(data);
    this.defaultExpandSelectGroupNavigation();
  }

  public async getParameterGroups() {
    if (!this.currentParameterGroup) {
      this.parameterGroupTree = [];
      return;
    }

    try {
      const dtos = await ParameterGroupService.getAllParameterGroups({
        parentId: this.currentParameterGroup ?? undefined,
        includeRoot: true,
      });

      if (!dtos) return;

      this.parameterGroupTree = this.createParamGroupTreeData(dtos);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} ParameterGroups`);
    } finally {
    }
  }

  public async getRootParameterGroupList(): Promise<void> {
    try {
      const dtos = await ParameterGroupService.getRootParameterGroups(parameterValueStore.filterStateParamValues.paramsDto);
      if (!dtos) return;

      runInAction(() => {
        this.rootParameterGroup = Utils.sortByField(dtos, 'orderNum').map((dto) => new RootParameterGroupModel(dto));
      });

      if (window.location.pathname === Routes.OBJECT_VALUE && !this.rootParameterGroup.map((data) => data.id).includes(this.currentParameterGroup)) {
        if (this.rootParameterGroup.length === 0) this.setCurrentParameterGroup(null);
        else this.setCurrentParameterGroup(Number(this.rootParameterGroup[0].id));
      }
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getListErrorMessage')} RootParameterGroups`);
      this.rootParameterGroup = [];
    } finally {
    }
  }

  public async getParameterGroupById(parameterGroupId?: number) {
    if (!this.isEditRootParameterGroup && !parameterGroupId) return null;

    try {
      const filterId = this.isEditRootParameterGroup ? this.currentParameterGroup! : parameterGroupId!;
      const dtos = await ParameterGroupService.getParameterGroupById(filterId);
      if (!dtos) return;

      runInAction(() => {
        this.parameterGroupById = dtos;
        if (this.parameterGroupById) {
          this.selectedParamGroup = new ParameterGroupModel(dtos);
        }
      });
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} ParameterGroup`);
    } finally {
    }
  }

  public async createRootParamGroup(): Promise<void> {
    if (this.selectedParamGroup.postRootDto === null) return;

    try {
      const result = await ParameterGroupService.postParameterGroup(this.selectedParamGroup.postRootDto);
      await this.getRootParameterGroupList();
      this.setCurrentParameterGroup(result);
      parameterStore.filterParameters.clear();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} RootParamGroup`);
    }
  }

  public async createParameterGroup(): Promise<void> {
    if (this.selectedParamGroup.postDto === null) return;

    try {
      const result = await ParameterGroupService.postParameterGroup(this.selectedParamGroup.postDto);

      if (this.isEditRootParameterGroup) {
        await this.getRootParameterGroupList();
        this.setCurrentParameterGroup(result);
        await this.getParameterGroups();
      } else {
        await this.getParameterGroups();

        if (parameterStore.filterParameters.parameterGroupId !== this.selectedParamGroup.id) {
          const findKey = this.listTransformParamGroupDto.find((item) => item.id === parameterStore.filterParameters.parameterGroupId);
          this.selectedParamGroup = new ParameterGroupModel(findKey);
        }
      }
      return;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} ParameterGroup`);
    }
  }

  public async updateParameterGroup(): Promise<void> {
    try {
      this.updateLoading = true;
      await ParameterGroupService.putParameterGroup(this.selectedParamGroup.putDto.id, this.selectedParamGroup.putDto);

      if (parameterStore.filterParameters.parameterGroupId !== this.selectedParamGroup.id) {
        const findKey = this.listTransformParamGroupDto.find((item) => item.id === parameterStore.filterParameters.parameterGroupId);
        this.selectedParamGroup = new ParameterGroupModel(findKey);
      }
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} ParameterGroup`);
    } finally {
      this.updateLoading = false;
    }
  }

  public async removeParameterGroups(): Promise<void> {
    const removeParameterGroups =
      this.checkedKeys.length > 0 ? this.checkedKeys.map((checkedKey: any) => Number(checkedKey)) : [this.selectedParamGroup.id!];

    const uniqueGroupIds = new Set(this.rootParameterGroup.map((data) => data.id));
    //удаляемая группа группа принадлежит корневой группе
    const hasRootGroup = removeParameterGroups.filter((groupId) => uniqueGroupIds.has(groupId));

    const currentFilterIndex = this.listTransformParamGroupDto.findIndex((f) => f.id === parameterStore.filterParameters.parameterGroupId);

    const previousGroupId =
      currentFilterIndex > 0
        ? this.listTransformParamGroupDto[currentFilterIndex - 1].id
        : this.listTransformParamGroupDto[currentFilterIndex].parentId;

    try {
      if (this.isEditRootParameterGroup || (hasRootGroup && hasRootGroup.length === 1)) {
        await ParameterGroupService.deleteParameterGroupBatch([hasRootGroup.length > 0 ? hasRootGroup[0] : Number(this.currentParameterGroup)]);
        this.setCurrentParameterGroup(null);
        await this.getRootParameterGroupList();
        if (this.rootParameterGroup.length > 0) {
          this.setCurrentParameterGroup(this.rootParameterGroup[0].id);
          this.initFilterStateParamGroup();
        }
      } else {
        await ParameterGroupService.deleteParameterGroupBatch(removeParameterGroups);
        this.setCheckedParameterGroup([]);
        await this.getParameterGroups();

        if (!this.listTransformParamGroupDto.find((f) => f.id === parameterStore.filterParameters.parameterGroupId)) {
          if (previousGroupId) {
            parameterStore.filterParameters.setParameterGroupId(previousGroupId);
            await parameterStore.getParameterGroupParameters();
          } else {
            if (this.rootParameterGroup.length > 0) {
              this.setCurrentParameterGroup(this.rootParameterGroup[0].id);
              this.initFilterStateParamGroup();
            } else {
              this.selectedParamGroup = new ParameterGroupModel();
              parameterStore.filterParameters.clear();
            }
          }
        } else {
          await parameterStore.getParameterGroupParameters();
        }
      }
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} ParameterGroups`);
    }
  }

  public async copyParameterGroup(): Promise<void> {
    if (!this.copyParamGroupId || !parameterStore.filterParameters.parameterGroupId) return;
    try {
      await ParameterGroupService.postCopyParamGroup({
        sourceId: this.copyParamGroupId,
        parentId: parameterStore.filterParameters.parameterGroupId,
      });
      this.getParameterGroups();
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.copyErrorMessage')} ParameterGroup`);
    }
  }

  public resetParameterGroup() {
    this.expandedKeys = [];
    this.searchArrays = [];
    this.searchValue = '';
    this.currentParameterGroup = null;
  }
}

export default new ParameterGroupStore();
