import components from './components';
import typography from './typography';

import { createTheme as createMuiTheme, Theme } from '@mui/material/styles';

const createTheme = (): Theme => {
  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 320,
        sm: 480,
        md: 768,
        lg: 1024,
        xl: 1280,
      },
      step: 0,
    },
    components: components,
    spacing: 4,
    typography: typography,
    palette: {
      common: {
        white: '#ffffff',
        black: '#303443',
      },
      text: {
        primary: 'rgba(15, 11, 31, 0.87)',
        secondary: 'rgba(15, 11, 31, 0.6)',
        disabled: 'rgba(15, 11, 31, 0.38)',
      },
      primary: {
        main: 'rgb(123, 104, 238)',
        light: '#E9E6FF',
        contrastText: 'rgba(255, 255, 255, 1)',
        dark: 'rgba(97, 79, 203, 1)',
      },
      secondary: {
        light: 'rgba(0, 0, 0, 0.04)',
        main: 'rgba(15, 11, 31, 0.6)',
      },
      error: {
        light: 'rgba(203, 33, 59, 0.04)',
        main: '#CB213B',
      },
      info: {
        light: 'rgba(28, 92, 228, 0.5)',
        main: 'rgba(28, 92, 228, 1)',
      },
      success: {
        light: 'rgba(18, 164, 77, 0.04)',
        main: '#12A44D',
      },
      background: {
        default: 'rgba(242, 242, 249, 1)',
      },
      action: {
        disabled: 'rgba(15, 11, 31, 0.12)',
        active: 'rgba(15, 11, 31, 0.54)',
        hover: 'rgba(15, 11, 31, 0.04)',
        selected: 'rgba(123, 104, 238, 0.08)',
        focus: 'rgba(176, 150, 240, 1)',
      },
      grey: {
        100: 'rgb(196, 196, 196)',
        200: 'rgba(123, 104, 238, 0.12)',
      },
    },
  });
};

export default createTheme;
