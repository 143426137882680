import React, { useEffect } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Translator, approvalStore, approvalSuggestionRowStore, parameterListValueStore, parameterValueStore } from 'stores';
import { DialogWrapper, EditParameterListValue } from 'components';
import { useConfirm } from 'components/App/Confirm';
import { ParameterListValueModel } from 'shared/models/ParameterListValueModel';

const ApprovalRowsListValueDialog: React.FC = () => {
  const confirm = useConfirm();

  useEffect(() => {
    return () => {
      approvalSuggestionRowStore.approvalSuggestionRow.clear();
      parameterValueStore.selectedParamValue.clear();

      runInAction(() => {
        parameterListValueStore.parameterListValues = [];
      });
    };
  }, []);

  const onCloseApprovalListValue = () => {
    approvalStore.setOpenApprovalDialog(false);
  };

  const onEditApprovalListValue = () => {
    if (approvalSuggestionRowStore.approvalSuggestionRow.parameterListValues.length === 0) {
      confirm.show(Translator.translate('ui.suggestionRow.confirmEmptyListDeleteMessage'), Translator.translate('actions.delete')).then(() => {
        approvalSuggestionRowStore.approvalSuggestionRow.approvalRowSuggestionId &&
          approvalSuggestionRowStore.removeRowSuggestionApproval(approvalSuggestionRowStore.approvalSuggestionRow.approvalRowSuggestionId);
        onCloseApprovalListValue();
      });

      return;
    }

    if (!approvalSuggestionRowStore.approvalSuggestionRow.defaultParameterListValues) {
      approvalSuggestionRowStore.createRowSuggestionApproval();
    } else {
      approvalSuggestionRowStore.updateRowSuggestionApproval();
    }

    onCloseApprovalListValue();
  };

  const onClickListValue = (list: ParameterListValueModel) => {
    approvalSuggestionRowStore.approvalSuggestionRow.setParameterListValues(list.id!);
  };

  const onCheckParamListValues = (checkedIds: number[]) => {
    approvalSuggestionRowStore.approvalSuggestionRow.setParameterListValueIds(checkedIds);
  };

  return (
    <DialogWrapper
      title="Список значений"
      subTitle={`Параметр: ${approvalSuggestionRowStore.approvalSuggestionRow.parameterName}`}
      open={approvalStore.isApprovalDialogOpen}
      onClose={onCloseApprovalListValue}
      onSubmit={onEditApprovalListValue}
      onCancel={onCloseApprovalListValue}
      maxWidth="md"
      hideActions={!approvalSuggestionRowStore.approvalSuggestionRow.canEditList}>
      <EditParameterListValue
        readOnly={!approvalSuggestionRowStore.approvalSuggestionRow.canEditList}
        onClickList={onClickListValue}
        onCheckParamListValues={onCheckParamListValues}
      />
    </DialogWrapper>
  );
};

export default observer(ApprovalRowsListValueDialog);
