import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldDataNode } from 'rc-tree';
import { Translator, itemStore, parameterGroupStore, parameterValueStore } from 'stores';
import { IconButtonNew, InputSearch, Select } from 'components';
import TreeData, { ButtonItemTitle, ParameterGroupTreeSearch, ParameterGroupTreeSelect } from 'components/App/TreeData';
import IconSearch from 'components/Icons/IconSearch';
import { Box, debounce, Fade, Paper, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { ParameterGroupModel } from 'shared/models';

import Utils from 'shared/utils/Utils';
import s from './ParamGroupFilterTree.module.scss';

interface IParamGroupFilterTree {
  parameterGroupHeight: number;
}

interface IParamTreeItemProps {
  title: React.ReactNode;
  name: string;
  id: number | null;
  key: string | number;
}

declare type IParamTreeItem = FieldDataNode<IParamTreeItemProps>;

const ParamGroupFilterTree: React.FC<IParamGroupFilterTree> = (props) => {
  const { parameterGroupHeight } = props;
  const [isOpenSearch, setOpenSearch] = useState(false);
  const parameterGroupRef = useRef<any>();

  useEffect(() => {
    if (parameterGroupRef.current && parameterValueStore.filterStateParamValues.parameterGroupId) {
      parameterGroupRef.current.scrollTo({ key: parameterValueStore.filterStateParamValues.parameterGroupId });
    }
  }, [parameterGroupStore.parameterGroupTree]);

  const onChangeRootGroup = async (value: number) => {
    parameterGroupStore.setCurrentParameterGroup(value);
    parameterGroupStore.getParameterGroups().then(() => {
      //раскрытие узла и выставление фильтра (первый элемент в дереве после элемента глобальной группы)
      parameterValueStore.defaultExpandSelectParamGroup();
    });
  };

  const onExpand = (expandedKeysValue: React.Key[]) => {
    parameterGroupStore.setExpandedKeys(expandedKeysValue);
  };

  const onSelect = (expandedKeysValue: React.Key[]) => {
    parameterValueStore.filterStateParamValues.setParameterGroupId(Number(expandedKeysValue));
    itemStore.getAllItemsHasValues();
  };

  const updateSearchParameterGroup = useCallback(
    debounce(() => {
      parameterValueStore.filterStateParamValues.setParameterGroupId(parameterGroupStore.selectedParamGroup.id);
      itemStore.getAllItemsHasValues();
    }, 350),
    []
  );

  const mapItems = (data: ParameterGroupModel[]): IParamTreeItem[] => {
    return data.map((item) => {
      const title = <ButtonItemTitle title={item.title as string} item={item} searchValue={parameterGroupStore.searchValue} />;

      if (item.children) {
        return {
          id: item.id,
          name: item.name,
          key: item.key,
          title: title,
          children: mapItems(item.children),
        };
      }

      return {
        id: item.id,
        name: item.name,
        key: item.key,
        title: title,
      };
    });
  };

  const onScroll = (key: React.Key) => {
    parameterGroupRef.current.scrollTo({ key: key });
  };

  const onChangeParamGroup = (value: ParameterGroupModel) => {
    updateSearchParameterGroup();
  };

  return (
    <Paper elevation={0} sx={(theme) => ({ p: 4, borderRadius: '12px', height: '100%', bgcolor: theme.palette.common.white })}>
      {parameterGroupStore.rootParameterGroup.length === 0 ? (
        <div className={s.infoWrapper}>
          <Typography>{Translator.translate('objectValues.paramGroup.emptyParamGroup')}</Typography>
        </div>
      ) : (
        <Stack gap={3} height="100%">
          <Stack height="38px" flexDirection="row" justifyContent="space-between" alignItems="center">
            {!isOpenSearch && (
              <ParameterGroupTreeSelect onScroll={onScroll} onChangeParamGroup={onChangeParamGroup} onChangeRootParamGroup={onChangeRootGroup} />
            )}

            {!isOpenSearch && (
              <IconButtonNew sx={{ ml: 2, mr: 1 }} onClick={() => setOpenSearch(true)}>
                <IconSearch />
              </IconButtonNew>
            )}

            {isOpenSearch && (
              <Box height="38px" width="100%">
                <Fade timeout={100} in={isOpenSearch}>
                  <div>
                    {isOpenSearch && (
                      <ParameterGroupTreeSearch
                        isExpanded
                        onScroll={onScroll}
                        onChangeParamGroup={onChangeParamGroup}
                        onCloseSearch={() => setOpenSearch(false)}
                      />
                    )}
                  </div>
                </Fade>
              </Box>
            )}
          </Stack>

          {parameterGroupStore.currentParameterGroup && Translator.selectedLocalization && (
            <Box height="100%">
              {parameterGroupStore.parameterGroupTree.length !== 0 && (
                <TreeData
                  height={parameterGroupHeight}
                  ref={parameterGroupRef}
                  isCheckStrictly={false}
                  selectedKey={[parameterValueStore.filterStateParamValues.parameterGroupId!]}
                  onSelect={onSelect}
                  onExpand={onExpand}
                  expandedKeys={parameterGroupStore.expandedKeys}
                  treeData={mapItems(parameterGroupStore.parameterGroupTree)}
                />
              )}
            </Box>
          )}
        </Stack>
      )}
    </Paper>
  );
};

export default observer(ParamGroupFilterTree);
