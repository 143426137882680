import { makeAutoObservable, runInAction } from 'mobx';
import { PartialApprovalService } from 'services';
import { Translator, approvalStore, informationBlockStore, partialApprovalStore, toastStore, usersStore } from 'stores';
import { ApprovalUserRole, PartialApprovalIteration, PartialApprovalIterationActions } from 'shared/enums';
import { Routes } from 'shared/enums/Routes';
import { IGetInformationBlockDto, IIterations, IPostApprovalUserDto, IPutApprovalUserDto, ISelectOption } from 'shared/interfaces';
import { ApprovalUserModel, PartialApprovalModel } from 'shared/models';

class PartialApprovalStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoading = false;

  public selectedPartialApproval = new PartialApprovalModel();

  public get iterations(): IIterations[] {
    let res: IIterations[] = [];
    res = Object.values(PartialApprovalIteration).map((item) => {
      return {
        iteration: item,
        isDone: false,
      };
    });

    res.splice(-1, 1);

    return res;
  }

  public usersFilteredOptions(currentUser: ApprovalUserModel): ISelectOption[] {
    let includeIds: number[] = [];

    switch (currentUser.role) {
      case ApprovalUserRole.Examiner: {
        includeIds = this.selectedPartialApproval.examiners
          .filter((f) => f.userId !== null && f.userId !== currentUser.userId)
          .map((examiner) => examiner.userId!);
        break;
      }

      case ApprovalUserRole.Observer: {
        includeIds = this.selectedPartialApproval.observers
          .filter((f) => f.userId !== null && f.userId !== currentUser.userId)
          .map((observer) => observer.userId!);
        break;
      }
    }

    return usersStore.usersForOptions.filter((f: ISelectOption) => !includeIds.includes(f.id as number));
  }

  public async getPartialApprovalById(id: number): Promise<void> {
    try {
      this.isLoading = true;
      const result = await PartialApprovalService.getPartialApproval(id);
      if (!result) return;

      runInAction(() => {
        this.selectedPartialApproval = new PartialApprovalModel(result);
      });

      if (!informationBlockStore.informationBlocks.find((_) => _.id === this.selectedPartialApproval.informationBlock.id)) {
        informationBlockStore.informationBlocks.push(this.selectedPartialApproval.informationBlock as IGetInformationBlockDto);
      }
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.getByIdErrorMessage')} PartialApproval`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createPartialApproval(): Promise<void | number> {
    if (!approvalStore.selectedApproval.id) return;
    if (this.selectedPartialApproval.postDto(approvalStore.selectedApproval.id) === null) return;

    try {
      this.isLoading = true;
      const result = await PartialApprovalService.postPartialApproval(this.selectedPartialApproval.postDto(approvalStore.selectedApproval.id!)!);
      if (!result) return;

      return result;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} PartialApproval`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updatePartialApproval(): Promise<void> {
    if (!this.selectedPartialApproval.id) return;

    try {
      this.isLoading = true;
      await PartialApprovalService.putPartialApproval(this.selectedPartialApproval.id, this.selectedPartialApproval.putDto);
      this.getPartialApprovalById(this.selectedPartialApproval.id);
      approvalStore.selectedApproval.id && approvalStore.getApprovalById(approvalStore.selectedApproval.id);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} PartialApproval`);
    } finally {
      this.isLoading = false;
    }
  }

  public async removePartialApproval(): Promise<void> {
    if (!this.selectedPartialApproval.id) return;

    try {
      this.isLoading = true;
      await PartialApprovalService.deletePartialApproval(this.selectedPartialApproval.id);

      approvalStore.selectedApproval.id && (await approvalStore.getApprovalById(approvalStore.selectedApproval.id));
      this.selectedPartialApproval.setId(approvalStore.selectedApproval.partialApprovals[0].id);
      this.getPartialApprovalById(this.selectedPartialApproval.id);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} PartialApproval`);
    } finally {
      this.isLoading = false;
    }
  }

  public async createUserPartialApproval(payload: IPostApprovalUserDto, idx?: number): Promise<number | void> {
    if (!this.selectedPartialApproval.id) return;

    try {
      this.isLoading = true;
      const result = await PartialApprovalService.postUserPartialApproval(this.selectedPartialApproval.id, payload);

      switch (payload.role) {
        case ApprovalUserRole.Examiner:
          idx !== undefined && this.selectedPartialApproval.examiners[idx].setId(result);
          this.selectedPartialApproval.setExaminersSnapshot(result);
          break;

        case ApprovalUserRole.Observer:
          idx !== undefined && this.selectedPartialApproval.observers[idx].setId(result);
          this.selectedPartialApproval.setObserverSnapshot(result);
          break;

        case ApprovalUserRole.Executor:
          this.selectedPartialApproval.executor.setId(result);
          break;
      }

      return result;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.createErrorMessage')} UserPartialApproval`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateUserPartialApproval(payload: IPutApprovalUserDto): Promise<void> {
    if (!this.selectedPartialApproval.id) return;

    try {
      this.isLoading = true;
      await PartialApprovalService.putUserPartialApproval(this.selectedPartialApproval.id, payload);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} UserPartialApproval`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateCancelPartialApproval(): Promise<void> {
    if (!this.selectedPartialApproval.id) return;

    try {
      this.isLoading = true;

      await PartialApprovalService.putCancelPartialApproval(this.selectedPartialApproval.id);
      await this.getPartialApprovalById(this.selectedPartialApproval.id);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} CancelPartialApproval`);
    } finally {
      this.isLoading = false;
    }
  }

  public async removeUserPartialApproval(currentUser: ApprovalUserModel, idx: number): Promise<void> {
    if (!this.selectedPartialApproval.id || !currentUser.id) return;

    try {
      this.isLoading = true;
      await PartialApprovalService.deleteUserPartialApproval(this.selectedPartialApproval.id, currentUser.id);

      switch (currentUser.role) {
        case ApprovalUserRole.Examiner:
          idx && this.selectedPartialApproval.deleteExaminer(idx);
          break;

        case ApprovalUserRole.Observer:
          idx && this.selectedPartialApproval.deleteObserver(idx);
          break;
      }
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.deleteErrorMessage')} UserPartialApproval`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateInfBlockPartialApproval(): Promise<void> {
    if (!this.selectedPartialApproval.id || !this.selectedPartialApproval.putInfBlockDto()) return;

    try {
      this.isLoading = true;
      await PartialApprovalService.putInfBlockPartialApproval(
        this.selectedPartialApproval.id,
        this.selectedPartialApproval.putInfBlockDto(informationBlockStore.selectedInformationBlock.postDto?.parameterGroupConfigurations)!
      );

      this.getPartialApprovalById(this.selectedPartialApproval.id);
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} InfBlockPartialApproval`);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateIterationPartialApproval(iteration: PartialApprovalIteration): Promise<boolean> {
    if (!this.selectedPartialApproval.id) return false;

    let action!: PartialApprovalIterationActions;

    switch (iteration) {
      case PartialApprovalIteration.Examination:
        action = PartialApprovalIterationActions.ToExecution;
        break;

      case PartialApprovalIteration.Execution:
        action = PartialApprovalIterationActions.ToRecheck;
        break;

      case PartialApprovalIteration.Recheck:
        action = PartialApprovalIterationActions.ToApproval;
        break;
    }

    try {
      this.isLoading = true;
      await PartialApprovalService.putIterationPartialApproval({
        partialApprovalIds:
          approvalStore.approvalSets.length > 0 && window.location.pathname.includes(Routes.PARTIAL_APPROVALS_SETS)
            ? approvalStore.partialApprovalSetIds
            : [partialApprovalStore.selectedPartialApproval.id!],
        patch: { action },
      });
      this.getPartialApprovalById(this.selectedPartialApproval.id);
      return true;
    } catch (e: any) {
      toastStore.showError(e.data?.message ?? `${Translator.translate('stores.updateErrorMessage')} IterationPartialApproval`);
      return false;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new PartialApprovalStore();
