import { BASE_PATH } from 'shared/constants/constants';
import {
  ICreateParameterGroupCommandDto,
  IGetAllParameterGroupDto,
  IGetParameterGroupParametersDto,
  IGetParameterGroupValuesDto,
  IParameterGroupByIdDto,
  IParameterGroupShortParams,
  IParameterGroupValues,
  IParamGroupCopyDto,
  IPostParameterGroupDocumentDto,
  IRootParameterGroupParams,
  IUpdateParameterGroupDto,
} from 'shared/interfaces';
import HTTPService from './HttpService';

class ParameterGroupService extends HTTPService {
  constructor() {
    super(BASE_PATH.PARAMETER_GROUP);
  }

  public getRootParameterGroups(params: IRootParameterGroupParams): Promise<IGetAllParameterGroupDto[]> {
    return this.GET('roots', { params });
  }

  public getAllParameterGroups(params: IParameterGroupShortParams): Promise<IGetAllParameterGroupDto[]> {
    return this.GET('children', { params });
  }

  public getAllParameterGroupParameters(params: IParameterGroupShortParams): Promise<IGetParameterGroupParametersDto[]> {
    return this.GET('children-parameters', { params });
  }

  public getAllParameterGroupValues(params: IParameterGroupValues): Promise<IGetParameterGroupValuesDto[]> {
    return this.GET('children-values', { params });
  }

  public getParameterGroupById(id: number): Promise<IParameterGroupByIdDto> {
    return this.GET<IParameterGroupByIdDto>(`${id}`);
  }

  public postParameterGroup(body: ICreateParameterGroupCommandDto): Promise<number> {
    return this.POST('', body);
  }

  public putParameterGroup(id: number, body: IUpdateParameterGroupDto) {
    return this.PUT(`${id}`, body);
  }

  public deleteParameterGroupBatch(body: number[]): Promise<number> {
    return this.DELETE('batch', body);
  }

  public downloadDocument(id: number, body: IPostParameterGroupDocumentDto): Promise<void> {
    return this.POST(`${id}/document`, body, {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
      },
    });
  }

  public postCopyParamGroup(body: IParamGroupCopyDto): Promise<number> {
    return this.POST('copy', body);
  }
}

export default new ParameterGroupService();
