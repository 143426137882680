import { observer } from 'mobx-react-lite';
import { Translator, approvalSuggestionRowStore, parameterListValueStore } from 'stores';
import { Typography, Link } from '@mui/material';
import TableView from '../TableView';
import { ITableColumn } from 'shared/interfaces';
import { DocumentListModel } from 'shared/models';
import { ParameterListValueModel } from 'shared/models/ParameterListValueModel';

interface IEditParameterListValueProps {
  onClickList: (value: ParameterListValueModel) => void;
  onCheckParamListValues: (checkedIds: number[]) => void;
  readOnly?: boolean;
}

const EditParameterListValue: React.FC<IEditParameterListValueProps> = (props) => {
  const { onClickList, onCheckParamListValues, readOnly = false } = props;

  const columns: ITableColumn[] = [
    {
      key: 'value',
      label: Translator.translate('common.editParameterListValue.columns.value'),
      width: '60%',
      align: 'left',
      cell: (row: ParameterListValueModel) => {
        return (
          <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
            {row.value}
          </Typography>
        );
      },
    },
    {
      key: 'documents',
      label: Translator.translate('common.editParameterListValue.columns.documents'),
      width: '40%',
      align: 'left',
      cell: (row: ParameterListValueModel) => {
        return getViewDocuments(row.documents);
      },
    },
  ];

  const getViewDocuments = (documents: DocumentListModel[]): JSX.Element => {
    return (
      <>
        {documents.map((document, index) => {
          if (document.url)
            return (
              <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                <Link target="_blank" onClick={(event: any) => event.stopPropagation()} href={document.url}>
                  {index === documents.length - 1 ? document.name : `${document.name} `}
                </Link>
                {document.documentPage && `, ${document.documentPage}`}
              </Typography>
            );
          else
            return (
              <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                {index === documents.length - 1 ? document.name : `${document.name} `}
                {document.documentPage && `, ${document.documentPage}`}
              </Typography>
            );
        })}
      </>
    );
  };

  return (
    <TableView
      data={parameterListValueStore.parameterListValues}
      checkable={approvalSuggestionRowStore.approvalSuggestionRow.parameterListValues}
      isHideHeader
      hideSelectRowOnMount
      readOnly={readOnly}
      columns={columns}
      isLoading={parameterListValueStore.isLoading}
      onChecked={onCheckParamListValues}
      onClick={onClickList}
    />
  );
};

export default observer(EditParameterListValue);
