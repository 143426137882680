import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FieldDataNode } from 'rc-tree/lib/interface';
import { auditStore, classStore, itemStore, toastStore, commentStore, parameterValueStore, Translator, appStore } from 'stores';
import { IconButtonNew, InputSearch, TreeItemSearch } from 'components';
import { useConfirm } from 'components/App/Confirm';
import { TreeData, ButtonItemTitle } from 'components/App/TreeData';
import CommentActionIcon from 'components/Icons/CommentActionIcon';
import CopyIcon from 'components/Icons/CopyIcon';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import HistoryIcon from 'components/Icons/HistoryIcon';
import IconSearch from 'components/Icons/IconSearch';
import InsertIcon from 'components/Icons/InsertIcon';
import PlusIcon from 'components/Icons/PlusIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import { Stack, Menu, MenuItem, Box, CircularProgress, Typography, Fade, Paper } from '@mui/material';
import { AuditTables, CommentMode, ObjectLayer } from 'shared/enums';
import { ItemModel } from 'shared/models';
import Utils from 'shared/utils/Utils';
import { AddItemDialog, EditItemDialog } from './Components';

import s from './ItemTree.module.scss';

const COMPACT_WIDTH_TREE = 375;

interface ITreeItemProps {
  title: React.ReactNode;
  name: string;
  id: number | null;
  key: string | number;
}

interface ItemTreeProps {
  itemHeight?: number;
}

declare type ITreeItem = FieldDataNode<ITreeItemProps>;

const ItemTree: React.FC<ItemTreeProps> = (props) => {
  const { treeTestItem } = itemStore;

  const itemRef = useRef<any>();
  const confirm = useConfirm();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [copyChildren, setCopyChildren] = useState(false);
  const [currentNode, setCurrentNode] = useState<React.Key | null>();
  const [isOpenSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    if (itemStore.treeTestItem.length !== 0) {
      const findKey = itemStore.listTransformItemDto.find((item) => item.id === parameterValueStore.filterStateParamValues.itemId);
      itemStore.selectItem = new ItemModel(findKey);

      if (itemRef.current) itemRef.current.scrollTo({ key: itemStore.selectItem.key });
    } else {
      itemStore.selectItem = new ItemModel();
    }
  }, [itemStore.treeTestItem, itemStore.itemHasValuesInGroup]);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    itemStore.setExpandedKeys(expandedKeysValue);
  };

  const onAdd = async (value?: ItemModel) => {
    if (value) itemStore.selectItem = new ItemModel(value);
    if (!itemStore.expandedKeys.includes(itemStore.selectItem.key)) {
      itemStore.setExpandedKeys([...itemStore.expandedKeys, itemStore.selectItem.key]);
    }

    if (!itemStore.selectItem.class?.layerId || itemStore.selectItem.class?.layerId === ObjectLayer.Main) {
      classStore.setSelectedLayer(ObjectLayer.Main);
      await classStore.getClassChildrenList();
    } else if (itemStore.selectItem.class?.layerId === ObjectLayer.Additional) {
      await classStore.getClassChildrenList();
    } else {
      classStore.setSelectedLayer(ObjectLayer.Additional);
      await classStore.getClasses();
      classStore.createClassTree();
    }

    //itemStore.setCreateItems(classStore.allClass);
    itemStore.setIsDialogCreateOpen(true);
  };

  const onEdit = async (value?: ItemModel) => {
    if (value) itemStore.selectItem = new ItemModel(value);

    itemStore.setIsDialogEditOpen(true);
  };

  const onDelete = async (value?: ItemModel) => {
    confirm.show(Translator.translate('objectValues.item.confirmMessage.deleteItem'), Translator.translate('actions.delete')).then(() => {
      if (value) itemStore.selectItem = value;

      itemStore.deleteItem();
    });
  };

  const onPaste = () => {
    itemStore.copyItem(copyChildren);
  };

  const onCopyOnlyItem = () => {
    setCopyChildren(false);
    itemStore.setCopyItemId(Number(itemStore.selectItem.id));
    setMenuAnchorEl(null);
  };

  const onCopyItemWithChildren = () => {
    setCopyChildren(true);
    itemStore.setCopyItemId(Number(itemStore.selectItem.id));
    setMenuAnchorEl(null);
  };

  const onAddCommentItem = () => {
    commentStore.setMode(CommentMode.Item);
    commentStore.setFilters(itemStore.selectItem.id!);
    commentStore.setOpenModal(true);
    commentStore.getAllComments();
  };

  const onCommentItem = (value: ItemModel) => {
    itemStore.selectItem = value;

    commentStore.setMode(CommentMode.Item);
    commentStore.setFilters(value.id!);
    commentStore.setOpenModal(true);
    commentStore.getAllComments();
  };

  const onHistoryView = (value?: ItemModel) => {
    if (value) itemStore.selectItem = value;

    auditStore.setDrawerOpen(true);
    auditStore.setAuditFilters(AuditTables.Items, Number(itemStore.selectItem.id));
  };

  const updateSearchItem = useCallback(
    debounce(() => {
      parameterValueStore.filterStateParamValues.setItemId(itemStore.selectItem.id);
    }, 350),
    []
  );

  const onUpSearch = () => {
    updateItem(-1);
    updateSearchItem();
  };

  const onDownSearch = () => {
    updateItem(1);
    updateSearchItem();
  };

  const updateItem = (searchDirection: 1 | -1) => {
    const findIndex = itemStore.searchArrays.findIndex((item) => item.key === itemStore.selectItem.key);

    const findKey = itemStore.listTransformItemDto.find((item) => item.key === itemStore.searchArrays[findIndex + searchDirection].key);
    itemStore.selectItem = new ItemModel(findKey);

    itemRef.current.scrollTo({ key: itemStore.searchArrays[findIndex + searchDirection].key });
  };

  const mapItems = (data: ItemModel[]): ITreeItem[] => {
    return data.map((item) => {
      const title = (
        <ButtonItemTitle
          title={item.title as string}
          item={item}
          searchValue={itemStore.searchValue}
          hovered={item.key === currentNode}
          itemStore={itemStore}
          onAdd={onAdd}
          onEdit={onEdit}
          onHistory={onHistoryView}
          onDelete={onDelete}
          onComment={onCommentItem}
          hasComments={item.hasComments}
          isMaster={item.isMaster}
          // Applying compact only to active node to prevent unnecessary redrawing
          compact={item.key === currentNode && itemStore.treeItemWidth! < COMPACT_WIDTH_TREE}
        />
      );

      if (item.children) {
        return {
          ...item,
          title: title,
          children: mapItems(item.children),
        };
      }

      return {
        ...item,
        title: title,
      };
    });
  };

  const onHover = (key: React.Key | null) => {
    setCurrentNode(key);
  };

  const onScroll = (key: React.Key) => {
    itemRef.current.scrollTo({ key: key });
    updateSearchItem();
  };

  const onCloseSearchItem = () => {
    itemStore.setSearchArrays([]);
    itemStore.setSearchValue('');
    itemStore.selectItem = new ItemModel();
    setOpenSearch(false);
  };

  return (
    <Paper elevation={0} sx={(theme) => ({ p: 4, borderRadius: '12px', height: '100%', bgcolor: theme.palette.common.white })}>
      <Stack height="100%" gap={3}>
        <Stack height="28px" flexDirection="row" justifyContent="space-between" alignItems="center">
          {!isOpenSearch && <Typography variant="overline">{String(Translator.translate('objectValues.objectsTitle')).toUpperCase()}</Typography>}

          {!isOpenSearch && (
            <IconButtonNew sx={{ mr: 1 }} onClick={() => setOpenSearch(true)}>
              <IconSearch />
            </IconButtonNew>
          )}

          {isOpenSearch && (
            <Box height="38px" width="100%">
              <Fade timeout={100} in={isOpenSearch}>
                <div>
                  {isOpenSearch && (
                    <TreeItemSearch isExpanded onScroll={onScroll} onPrev={onUpSearch} onNext={onDownSearch} onCloseSearch={onCloseSearchItem} />
                  )}
                </div>
              </Fade>
            </Box>
          )}
        </Stack>

        <Stack
          sx={(theme) => ({
            'button svg': {
              fill: theme.palette.primary.main,
            },
          })}
          flexDirection="row"
          gap={2}>
          <IconButtonNew tooltip={Translator.translate('actions.add')} onClick={() => onAdd()}>
            <PlusIcon />
          </IconButtonNew>

          <IconButtonNew tooltip={Translator.translate('actions.edit')} onClick={() => onEdit()} disabled={!itemStore.selectItem.id}>
            <EditPencilIcon />
          </IconButtonNew>

          <IconButtonNew
            onClick={(e) => setMenuAnchorEl(e.currentTarget)}
            tooltip={Translator.translate('actions.copy')}
            disabled={!itemStore.selectItem.id}>
            <CopyIcon />
          </IconButtonNew>

          <IconButtonNew onClick={onPaste} tooltip={Translator.translate('actions.past')} disabled={!itemStore.copyItemId}>
            <InsertIcon />
          </IconButtonNew>

          <IconButtonNew onClick={() => onDelete()} tooltip={Translator.translate('actions.delete')} disabled={!itemStore.selectItem.id}>
            <TrashIcon />
          </IconButtonNew>

          {appStore.isShowComments && (
            <IconButtonNew onClick={onAddCommentItem} tooltip={Translator.translate('actions.comment')} disabled={!itemStore.selectItem.id}>
              <CommentActionIcon />
            </IconButtonNew>
          )}

          <IconButtonNew tooltip={Translator.translate('actions.changeHistory')} onClick={() => onHistoryView()} disabled={!itemStore.selectItem.id}>
            <HistoryIcon />
          </IconButtonNew>
        </Stack>

        <Menu open={!!menuAnchorEl} anchorEl={menuAnchorEl} onClose={() => setMenuAnchorEl(null)}>
          <MenuItem onClick={onCopyOnlyItem}>{Translator.translate('objectValues.item.copyItemType.object')}</MenuItem>
          <MenuItem onClick={onCopyItemWithChildren}>{Translator.translate('objectValues.item.copyItemType.nestedObjects')}</MenuItem>
        </Menu>

        {itemStore.waitingItem ? (
          <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          treeTestItem.length !== 0 && (
            <TreeData
              treeData={mapItems(treeTestItem)}
              ref={itemRef}
              expandedKeys={itemStore.expandedKeys}
              selectedKey={[String(parameterValueStore.filterStateParamValues.itemId)]}
              onSelect={itemStore.setSelectItem}
              onExpand={onExpand}
              height={props.itemHeight}
              isCheckStrictly
              onHover={onHover}
            />
          )
        )}
      </Stack>

      {itemStore.isDialogCreateOpen && <AddItemDialog />}
      {itemStore.isDialogEditOpen && <EditItemDialog />}
    </Paper>
  );
};

export default observer(ItemTree);
